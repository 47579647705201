import { cn } from '../../utils/cn';

const PlayIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={cn('w-4 h-4 fill-white', className)}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 14 14'
      fill='none'
    >
      <path
        stroke=''
        strokeWidth='1.5'
        d='M11.797 7.488l-6.872 3.68c-.478.256-.96-.108-.96-.489V3.321c0-.381.482-.745.96-.489l6.872 3.68c.414.221.414.755 0 .976z'
      ></path>
    </svg>
  );
};

function PlayCircleIcon(props: { className?: string }): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 48 48'
      className={props.className || 'w-10 h-10'}
    >
      <g filter='url(#filter0_d_49952_60488)'>
        <circle cx='24' cy='20' r='20' fill='#fff'></circle>
        <path
          fill='#000'
          d='M31.48 19.142a.968.968 0 010 1.716l-10.92 6.008c-.693.381-1.56-.096-1.56-.858V13.992c0-.763.867-1.24 1.56-.859l10.92 6.008z'
        ></path>
      </g>
      <defs>
        <filter
          id='filter0_d_49952_60488'
          width='48'
          height='48'
          x='0'
          y='0'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feColorMatrix
            in='SourceAlpha'
            result='hardAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          ></feColorMatrix>
          <feOffset dy='4'></feOffset>
          <feGaussianBlur stdDeviation='2'></feGaussianBlur>
          <feComposite in2='hardAlpha' operator='out'></feComposite>
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'></feColorMatrix>
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_49952_60488'
          ></feBlend>
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_49952_60488'
            result='shape'
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

function RoundedPlayIcon(props: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      className={props.className || 'w-5 h-5 fill-current'}
    >
      <path
        // stroke='#1B1B1E'
        strokeWidth='1.5'
        d='M15.828 10.991l-9.163 5.257c-.709.406-1.63-.102-1.63-.992V4.744c0-.89.921-1.398 1.63-.992l9.163 5.257c.753.432.753 1.55 0 1.982z'
      ></path>
    </svg>
  );
}

export { PlayIcon, PlayCircleIcon, RoundedPlayIcon };
